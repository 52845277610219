const messages ={
     pt: {
     	translations: {
     		header:{
     			button:'Saiba Mais',
                    paragraph:'Cozinhar é a arte de harmonizar culturas, de saborear e contar histórias.'
     		},
               nav:{
                    contact: 'CONTATO',
                    gallery: 'GALERIA',
                    chef:'A CHEF'
               },
               about:{
                    title:'A CHEF',
                    text:'Há mais de 10 anos que me divirto na alquimia dos temperos. Comecei a trabalhar com catering, me encontrei no curso de gastronomia e logo comecei a trabalhar para restaurantes no Recife. A paixão por viajar me levou a trabalhar em 5 países e a buscar minha raizes familiares na Itália, onde tive a honra de vestir o avental do restaurante 3 estrelas Michelin, Osteria Francescana, pelo Chef Massimo Bottura, e foi lá que conclui a minha segunda formação gastronomica. Meu estilo de cozinha tem raízes brasileiras e italianas, com um twist de temperos e combinações dos lugares por onde passei. Gosto de harmonizar sabores, texturas e temperaturas para surpreender o paladar. Trago para a mesa a produção local e a sazonalidade das suas receitas. Hoje atuo como personal chef, com consultorias e cooking class.',
                    subtitle:'VOCÊ SABE COM QUEM A CHEF BRUNA GRIMALDI JÁ TRABALHOU?'
               },
               gallery:{
                    title:'GALERIA',
                    subtitle:'Uma nova experiência em cada prato',
               },
               news:{
                    subtitle:'Acompanha as mídias sociais para ficar por dentro das novidades.'
               },
               contact:{
                    title:'FALA COM A CHEF',
                    subtitle:'Por favor, preencha todos os campos do formulário abaixo. A Chef Bruna Grimaldi responderá o mais rápido possível.',
                    button:'Enviar',
                    info:'Informações',
                    location:'Localização',
                    address: 'Recife, PE',
                    phone:'Telefone',
                    number:'+55 81 973277360'

               }

     	}
     }
}

export {messages}