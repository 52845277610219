import React from 'react';
import {i18n} from '../translate/i18n';


export const Header = (props) => {


  return (
    <header id='header'>
      <div className='intro'>
        <div className='overlay-back'>
          <div className='container'>
             <div data-spy="affix" id="dot-nav-social">
                <ul>
                  <a href="#page-top" className='page-scroll'>
                  <li className="awesome-tooltip-social active " title='Home'>
                  <i style={{color: '#777'}}  className='fa fa-home'></i>
                  </li> 
                  </a>

                  <a href='https://www.instagram.com/chefbrunagrimaldi' className='page-scroll'>  
                  <li className="awesome-tooltip-social" title="Instagram">
                  <i style={{color: '#777'}}  className='fa fa-instagram'></i>
                  </li>
                  </a>
                  
                  <a href='https://api.whatsapp.com/send?phone=5581973277360&text=Ol%C3%A1,%20eu%20sou%20a%20Chef%20Bruna%20Grimaldi,%20em%20que%20posso%20te%20ajudar?'>                  
                  <li className="awesome-tooltip-social" title="whatsapp">
                                    <i style={{color: '#777'}}  className='fa fa-whatsapp'></i>

                  </li>
                  </a>

                  <a href='#contact' className='page-scroll'>
                  <li className="awesome-tooltip-social" title="Contato"> 
                                    <i style={{color: '#777'}} className='fa fa-envelope'></i>

                  </li>
                  </a>
                </ul>
              </div>    
             <div className='row'>
              <div className='col-md-8 col-md-offset-2 intro-text'>
                <img className="img-responsive-header" src={props.data ? props.data.img : 'Loading'} alt='brand'/>
                <p> {i18n.t('header.paragraph')} </p>
                <a
                  href='#about'
                  className='btn btn-custom btn-lg page-scroll'
                >
                 {i18n.t('header.button')} 
                </a>{' '}

              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

