import React from 'react'
import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'
import { Gallery, Item } from 'react-photoswipe-gallery'
import {i18n} from '../translate/i18n';


export const Portfolio = (props) => {
  return (
    <div id='portfolio' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>{i18n.t('gallery.title')}</h2>
          <p>
              {i18n.t('gallery.subtitle')}
          </p>
        </div>
  <Gallery>
    <Item
      original="./img/portfolio/03-large.jpg"
      thumbnail="./img/portfolio/03-large.jpg"
      width="324"
      height="328"
    >
      {({ ref, open }) => (
        <img style={{width:'20%'}} ref={ref} onClick={open} src="./img/portfolio/03-large.jpg" alt='pinça' />
      )}
    </Item>
        <Item
      title="Lagosta"
      original="./img/portfolio/09-large.jpg"
      thumbnail="./img/portfolio/09-large.jpg"
      width="324"
      height="328"
    >
      {({ ref, open }) => (
        <img style={{width:'20%'}} ref={ref} onClick={open} src="./img/portfolio/09-large.jpg" alt='lobster' />
      )}
    </Item>
    <Item
      title="Torta"
      original="./img/portfolio/04-large.jpg"
      thumbnail="./img/portfolio/04-large.jpg"
      width="324"
      height="328"
    >
      {({ ref, open }) => (
        <img style={{width:'20%'}} ref={ref} onClick={open} src="./img/portfolio/04-large.jpg" alt='dessert' />
      )}
    </Item>
        <Item
      title="Salmão"
      original="./img/portfolio/05-large.jpg"
      thumbnail="./img/portfolio/05-large.jpg"
      width="324"
      height="328"
    >
      {({ ref, open }) => (
        <img style={{width:'20%'}} ref={ref} onClick={open} src="./img/portfolio/05-large.jpg" alt='salmon' />
      )}
    </Item>
    <Item
      original="./img/portfolio/07-large.jpg"
      thumbnail="./img/portfolio/07-large.jpg"
      width="324"
      height="328"
    >
      {({ ref, open }) => (
        <img style={{width:'20%'}} ref={ref} onClick={open} src="./img/portfolio/07-large.jpg" alt='delivering' />
      )}
    </Item>
    <Item
      original="./img/portfolio/08-large.jpg"
      thumbnail="./img/portfolio/08-large.jpg"
      width="324"
      height="328"
    >
      {({ ref, open }) => (
        <img style={{width:'20%'}} ref={ref} onClick={open} src="./img/portfolio/08-large.jpg" alt='board' />
      )}
    </Item>
    <Item
      title="Atum"
      original="./img/portfolio/01-large.jpg"
      thumbnail="./img/portfolio/01-large.jpg"
      width="324"
      height="328"
    >
      {({ ref, open }) => (
        <img style={{width:'20%'}} ref={ref} onClick={open} src="./img/portfolio/01-large.jpg" alt='tuna' />
      )}
    </Item>
    <Item
      original="./img/portfolio/10-large.jpg"
      thumbnail="./img/portfolio/10-large.jpg"
      width="324"
      height="328"
    >
      {({ ref, open }) => (
        <img style={{width:'20%'}} ref={ref} onClick={open} src="./img/portfolio/10-large.jpg" alt='osteria' />
      )}
    </Item>
    <Item
      title='Falafel'
      original="./img/portfolio/06-large.jpg"
      thumbnail="./img/portfolio/06-large.jpg"
      width="324"
      height="328"
    >
      {({ ref, open }) => (
        <img style={{width:'20%'}} ref={ref} onClick={open} src="./img/portfolio/06-large.jpg" alt='falafel' />
      )}
    </Item>
    <Item
      title="Vol-au-vent"
      original="./img/portfolio/02-large.png"
      thumbnail="./img/portfolio/02-large.png"
      width="324"
      height="328"
    >
      {({ ref, open }) => (
        <img style={{width:'20%'}} ref={ref} onClick={open} src="./img/portfolio/02-large.png" alt='figo' />
      )}
    </Item>    
  </Gallery>

</div>
</div>
  )
}
