import React, {useState, useEffect, useRef} from 'react'
import Resume from './resume.pdf';
import {i18n} from '../translate/i18n';

let useClickOutside =(handler) => {
  let domNode = useRef();

  useEffect(()=> { 
  
  let maybeHandler =(event)=> { 
    if (!domNode.current.contains(event.target)){
      handler();
      }
    };
  
    document.addEventListener("mousedown", handler); 


  return ()=> {
    document.removeEventListener("mousedown", handler)
    };
  });
  
  return domNode;
};


const I18N_STORAGE_KEY= 'i18nextLng'


export const Navigation = (props) => {

const[language]=useState(localStorage.getItem(I18N_STORAGE_KEY)) 

const handleSelectChange = event => {
  // console.log(event.target.value)
  localStorage.setItem(
    I18N_STORAGE_KEY,
    event.target.value
    )
  window.location = window.location
}


let [isOpen, setIsOpen] = useState(false);

let domNode = useClickOutside(()=>{
  setIsOpen(false);
});


  return (
    <nav id='menu' className='navbar navbar-default navbar-fixed-top'>
      <div className='container'>
        <div className='navbar-header'>
          <button
            type='button'
            className='navbar-toggle collapsed'
            data-toggle='collapse'
            data-target='#bs-example-navbar-collapse-1'
            aria-expanded="true"
            onClick={() => setIsOpen(!isOpen)}
            >
            {' '}
            <span className='sr-only'>Toggle navigation</span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
          </button>
          <a className='navbar-brand page-scroll' href='#page-top'>
            <span>C</span>hef <span>B</span>runa <span>G</span>rimaldi
          </a>{' '}
        </div>


        <div ref={domNode}         
          className='collapse navbar-collapse'
          id='bs-example-navbar-collapse-1'
        >
          <ul className='nav navbar-nav navbar-right' data-toggle="collapse" data-target=".in" >
           
            <li >
              <a href='#about' data-toggle="collapse" data-target=".in" className='page-scroll left'>
                {i18n.t('nav.chef')}
              </a>
            </li>
            <li>
              <a  href='#portfolio' data-toggle="collapse" data-target=".in"  className='page-scroll'>
               {i18n.t('nav.gallery')}
              </a>
            </li>
            <li >
              <a  href='#team' data-toggle="collapse" data-target=".in"  className='page-scroll'>
                News
              </a>
            </li>
            <li >
              <a href='#contact' data-toggle="collapse" data-target=".in" className='page-scroll'>
               {i18n.t('nav.contact')}
              </a>
            </li>
            <li>
              <a href={Resume} data-toggle="collapse" data-target=".in" >
                CV
              </a>
            </li>
            <li data-toggle="collapse" data-target=".in" style={{marginRight:'0.5rem', paddingLeft:'2.2rem', marginTop:'1.7rem'}}>
                  <select onChange={handleSelectChange} value={language}>
                  <option onChange={handleSelectChange} value="en">EN</option>
                  <option onChange={handleSelectChange} value="pt">PT</option>
                  </select>
            </li>

          </ul>

        </div>
      </div>
    </nav>
  )
}





// const toggle = () => setIsOpen(!isOpen);

//   // This can be used to close the menu, e.g. when a user clicks a menu item
//   const closeMenu = () => {
//    setIsOpen(false)
//   };
