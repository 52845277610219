import { useState } from 'react'
import emailjs from 'emailjs-com'
import {i18n} from '../translate/i18n';

const initialState = {
  name: '',
  email: '',
  message: '',
}
export const Contact = (props) => {
  
  const [{ name, email, message }, setState] = useState(initialState)

  const handleChange = (e) => {
    const { name, value } = e.target
    setState((prevState) => ({ ...prevState, [name]: value }))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log(name, email, message)
    emailjs
      .sendForm(
        "service_i2k3le8","template_ichcy5i", e.target, "user_1k0WzvGuuB1f0J5kiyrEw"
      )
      .then(
        (result) => {
          console.log(result.text)
          e.target.reset();
          alert ('Mensagem Enviada')
          
        },
        (error) => {
          console.log(error.text)
        }
      )
  }
  return (
    <div>
      <div id='contact'>
        <div className='container'>
          <div className='col-md-8'>
            <div className='row'>
              <div className='section-title'>
                <h2>{i18n.t('contact.title')}</h2>
                <p>
                  {i18n.t('contact.subtitle')}
                </p>
              </div>
              <form name='sentMessage' validate onSubmit={handleSubmit}>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='text'
                        id='name'
                        name='name'
                        className='form-control'
                        placeholder='Name'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='email'
                        id='email'
                        name='email'
                        className='form-control'
                        placeholder='Email'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                </div>
                <div className='form-group'>
                  <textarea
                    name='message'
                    id='message'
                    className='form-control'
                    rows='4'
                    placeholder='Message'
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className='help-block text-danger'></p>
                </div>
                <div id='success'></div>
                <button type='submit' className='btn btn-custom btn-lg'>
                  {i18n.t('contact.button')}
                </button>
              </form>
            </div>
          </div>
          <div className='col-md-3 col-md-offset-1 contact-info'>
            <div className='contact-item'>
              <h3>{i18n.t('contact.info')}</h3>
              <p>
                <span>
                  <i className='fa fa-map-marker'></i> 
                  {i18n.t('contact.location')}
                   </span>
                {i18n.t('contact.address')}
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-phone'></i> 
                  {i18n.t('contact.phone')}
                </span>{' '}
                {i18n.t('contact.number')}
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-envelope-o'></i> Email
                </span>{' '}
                {props.data ? props.data.email : 'loading'}
              </p>
            </div>
          </div>
          <div className='col-md-12'>
            <div className='row'>
              <div className='social'>
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : '/'} className='page-scroll'>
                      <i className='fa fa-home'></i>
                    </a>
                  </li>
                  <li>
                    <a  href={props.data ? props.data.whatsapp : '/'}>
                      <i className='fa fa-whatsapp'></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.instagram : '/'}>
                      <i className='fa fa-instagram'></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id='footer'>
        <div className='container text-center'>
          <p>
            copyright @chefbrunagrimaldi all rights reserved {' '}
            <a style={{color:'black'}} href='http://www.gitlab/EveRojas' rel='nofollow'>
             EveRojas
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}
