const messages ={
     en: {
     	translations: {
     		header:{
     			button:'Know More',
                    paragraph:'Cooking is the art of harmonizing cultures, savoring and telling stories.'
     		},
               nav:{
                    contact: 'CONTACT',
                    gallery: 'GALLERY',
                    chef:'THE CHEF'
               },
               about:{
                    title:'THE CHEF',
                    text:'I have been enjoying the alchemy of spices for over 10 years. I started working with catering, found myself on a gastronomy course and soon started working for restaurants in my hometown - Recife/BR. The passion for traveling led me to work in 5 countries and the searching for my family roots in Italy, where I had the honor of wearing the apron of the 3 Michelin star restaurant, Osteria Francescana, by Chef Massimo Bottura. It was there where I concluded my second gastronomic training. My style of cooking has Brazilian and Italian roots with a twist of spices and combinations from the places I have been to. I like to harmonize flavors, textures and temperatures to surprise the palate. I bring to the table the local production and the seasonality of its recipes. Today I work as a personal chef, with consultancies and cooking classes.',
                    subtitle:'DO YOU KNOW WITH WHOM CHEF BRUNA GRIMALDI HAS ALREADY WORKED WITH?'
               },
               gallery:{
                    title:'GALLERY',
                    subtitle:'A new experience in every dish',
               },
               news:{
                    subtitle:'Follow the Chef on Social Media'
               },
               contact:{
                    title:'GET IN TOUCH',
                    subtitle:'Please fill in all required fields below. Chef Bruna Grimaldi will get back to you as soon as possible.',
                    button:'Send',
                    info:'Info',
                    location:'Location',
                    address:'London, UK',
                    phone:'Phone',
                    number:'+44 7494190056'
               }

     	}
     }
}

export {messages}