import { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { About } from "./components/about";
import { Portfolio } from "./components/portfolio";
import { Team } from "./components/Team";
import { Contact } from "./components/contact";
import SmoothScroll from "smooth-scroll";
import JsonData from "./Data/data.json"
import "./App.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  const user = false;


  return (
    <div>
      <Navigation data={landingPageData.Navigation} />
      <Header data={landingPageData.Header} />
      <About data={landingPageData.About} />
      <Portfolio />
      <Team data={landingPageData.Team} />
      <Contact data={landingPageData.Contact} />

    </div>
  );
};

export default App;
